import React, { ReactNode } from "react";
import { useField, useFormikContext } from "formik";
import FormItem, { FormItemProps } from "./FormItem";

type CheckboxFormItemProps = Pick<FormItemProps, "name" | "style"> & {
  readonly disabled?: boolean;
  readonly label?: ReactNode;
};

function CheckboxFormItem({
  name,
  style,
  disabled,
  label,
}: CheckboxFormItemProps) {
  const [{ value, ...field }] = useField<boolean>(name);
  const { isSubmitting } = useFormikContext();
  const fieldId = `${name}Field`;

  return (
    <FormItem name={name} fieldId={fieldId} style={style}>
      <label htmlFor={fieldId}>
        <input
          id={fieldId}
          type="checkbox"
          disabled={disabled || isSubmitting}
          checked={value}
          {...field}
        />{" "}
        {label}
      </label>
    </FormItem>
  );
}

export default CheckboxFormItem;
